export default class MnemonicManager {
    constructor(snackbarService){   
        this.counter = 1;
        this.words = [];
        this.column1 = [];
        this.column2 = [];
        this.snackbarService = snackbarService;
    }

    
    pushWord(word) {
        if (this.words === null || this.words.length == 23) {
            this.snackbarService.showMessage('Уже выбрано 23 слова');
            return;
        }

        word.counters.push(this.counter);
        this.words.push(word);
        this.counter++;
    }

    canPopWord() {
        return this.words && this.words.length !== 0;
    }

    popWord() {
        if (!this.canPopWord()) return;

        var word = this.words.pop();
        word.counters.pop();

        this.counter--;
    }

    canClearPhrase() {
        return this.counter !== 1 && this.words;
    }

    clearPhrase() {
        if (!this.canClearPhrase()) return;

        this.counter = 1;

        this.words.forEach(word => {
            word.counters = [];
        });

        this.words = [];
    }

    canGetPhrase() {
        return this.words && this.words.length == 23;
    }

    getPhrase() {
        // validated
        if (!this.canGetPhrase()) throw 'Недостаточно слов для проверки фразы';

        // build a result
        var result = [];
        for (var i=0; i < this.words.length; i++) {
            result.push(this.words[i].index);
        } 

        // clear current data
        this.clearPhrase();

        // return result
        return result;
    }

    restorePhrase(indexes) {
        if (indexes === null || indexes.length ===0) throw 'Некорректный размер фразы';

        // clear current phrase
        this.clearPhrase();

        // parse indexes, find word by index and add it to words list
        for (var i = 0; i < indexes.length; i++) {
            let index = indexes[i].toLowerCase();
            let letter = index.charAt(0);

            let column = [];
            if (letter === 'l') {
                column = this.column1;
            }
            else if (letter === 'r') {
                column = this.column2;
            }
            else {
                throw 'Неизвестный индекс слова';
            }

            let word = null;
            for (var j = 0; j < column.length; j++) {
                if (word) break;

                let group = column[j];
                for (var k =0; k < group.entries.length; k++) {
                    if (word) break;

                    if (group.entries[k].index.toLowerCase() == index) {
                        word = group.entries[k];
                    }
                }
            }

            if (word !== null) {
                this.pushWord(word);
            }
        }
    }
}
export default class RequestsPage {
    constructor(pageIndex, pageSize, totalCount) {
        this.pageIndex = pageIndex ?? 0;
        this.pageNumber = pageIndex !== null
            ? pageIndex + 1
            : 1;
        this.pageSize = pageSize;
        this.totalCount = totalCount ?? 0;
        this.pagesCount = Math.ceil(this.totalCount / this.pageSize);
    }
}
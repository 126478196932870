<template>
    <v-container class="bwkr__table mb-5 mb-md-0 mr-0 pr-0 pr-md-3">
        <v-row>
            <v-col class="bwkr__table__column pr-sm-0 pb-sm-0">
               <IncompleteMnemonicTableColumn 
                    :categories="column1" 
                    :mnemonicManager="mnemonicManager"
                    @showExplanation="showGenerationExplanations"/>
            </v-col>
            <v-col class="bwkr__table__column pl-sm-0 pb-sm-0">
                <IncompleteMnemonicTableColumn 
                    :categories="column2"
                    :mnemonicManager="mnemonicManager"
                    :showRightBorder="true"
                    @showExplanation="showGenerationExplanations"/>
            </v-col>
            <v-overlay
                :value="showOverlay">
                <v-card>
                    <v-card-title>
                        <v-container>
                            <div class="d-flex flex-wrap">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{on, attrs}">
                                        <v-chip 
                                            class="mr-3"
                                            v-on="on"
                                            v-bind="attrs">
                                           {{ code }}
                                        </v-chip>
                                    </template>
                                    Так выглядит исходный код, для которого смотрим примеры генерации 
                                </v-tooltip>
                                
                                <h3>
                                    - текущий код
                                </h3>
                                <div class="ml-auto">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{on, attrs}">
                                            <v-btn
                                                v-on="on"
                                                v-bind="attrs"
                                                color="primary"
                                                @click="hideGenerationExplanations"
                                                >
                                                    X
                                                </v-btn>
                                        </template>
                                        Закрыть окно с пояснениями
                                    </v-tooltip>
                                </div>
                            </div>
                        </v-container>                        
                    </v-card-title>
                    <v-card-text>
                        <v-data-iterator
                            :items="generationExplanations"
                            item-key="ruleName"
                            loading="generationExplanations === null"
                            loading-text="Загрузка пояснений..."
                            hide-default-footer
                            :page="pageNumber"
                            :items-per-page="pageSize">
                            <template v-slot:default="{ items }">
                                <v-row 
                                    v-for="explanation in items"
                                    :key="explanation.ruleName">
                                    <v-card class="my-2 mx-2" style="width: 100%;" light>
                                        <v-card-title>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-chip 
                                                        class=mr-3
                                                        v-on="on"
                                                        v-bind="attrs">
                                                        {{ explanation.generatedCode }}
                                                    </v-chip>
                                                </template>
                                                Это код, который будет сгенерирован из исходного кода при применении этого правила
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{on, attrs}">
                                                    <span
                                                        v-on="on"
                                                        v-bind="attrs">
                                                        -  {{ explanation.ruleName }}
                                                    </span>
                                                </template>
                                                Это название правила
                                            </v-tooltip>
                                        </v-card-title>
                                        <v-card-text>
                                            {{ explanation.ruleDescription }}
                                        </v-card-text>
                                    </v-card>
                                </v-row>
                            </template>
                        </v-data-iterator>

                        <v-pagination
                            class="mt-3"
                            v-model="pageNumber"
                            :length="pageCount"
                            :total-visible="6">
                        </v-pagination>
                    </v-card-text>
                </v-card>
                 
            </v-overlay>
        </v-row>
    </v-container>
</template>

<style>

.bwkr__table {
    padding: 0 !important;
}

.bwkr__table__column {
    min-width: 330px !important;
}

</style>

<script>

import IncompleteMnemonicTableColumn from './IncompleteMnemonicsTableColumn.vue'
import MnemonicWordsGroup from './../core/MnemonicWordsGroup.js'
import MnemonicWord from './../core/MnemonicWord.js'
import MnemonicManager from './../core/MnemonicManager.js'
import SnackbarService from './../core/SnackbarService.js'
import UrlBuilder from './../core/UrlBuilder.js'
import SortMode from './../core/SortMode.js'
import SortModes from './../core/SortModes.js'

//import tableJson from './../data/table.json'

import axios from "axios"

export default {
    name: "CheckRequests",

    data() {
        return {
            column1: [],
            column2: [],
            showOverlay: false,
            generationExplanations: [],
            code: null,
            pageNumber: 1,
            pageSize: 3,
        }
    },

    props: {
       urlBuilder: UrlBuilder,
       mnemonicManager: MnemonicManager,
       snackbarService: SnackbarService,
       sortMode: SortMode
    },

    components: {
        IncompleteMnemonicTableColumn
    },

    computed: {
        pageCount() {
            return Math.ceil(this.generationExplanations.length / this.pageSize);
        }
    },

    watch: {
        sortMode: async function() {
            await this.sortAsync();
        }
    },

    methods: {
        async fetchTableAsync() {
            try {
                console.log('Started downloading table data...');
                
                const url = this.urlBuilder.buildUrlForTableData();
                const response = await axios.get(url);
                if (response.status != 200) {
                    throw "Не удается подключиться к серверу";
                }
                
                if (!response.data.isSuccess)
                {
                    console.log(response.data.errors);
                    throw "Упс... Что-то пошлоа не так. Попробуйте позже.";
                }

                this.column1 = response.data.body.column1.map(group => 
                    new MnemonicWordsGroup(
                        group.name,
                        group.entries.map(word => 
                            new MnemonicWord(
                                word.index,
                                word.name,
                                word.code,
                                word.isOptional,
                                word.isDuplicatedInColumn,
                                word.isDuplicatedInTable))));
                this.column2 = response.data.body.column2.map(group => 
                    new MnemonicWordsGroup(
                        group.name,
                        group.entries.map(word => 
                            new MnemonicWord(
                                word.index,
                                word.name,
                                word.code,
                                word.isOptional,
                                word.isDuplicatedInColumn,
                                word.isDuplicatedInTable))));

                this.mnemonicManager.column1 = this.column1;
                this.mnemonicManager.column2 = this.column2;

                console.log('Completed downloading table data');
            } 
            catch (err) {
                if (err.response) {
                    // client received an error response (5xx, 4xx)
                    console.log("Server Error:", err)
                    this.snackbarService.showMessage('Ошибка взаимодействия с сервером. Не удалось получить данные таблицы. Попробуйте повторить попытку позже.');
                } else if (err.request) {
                    // client never received a response, or request never left
                    console.log("Network Error:", err)

                    this.snackbarService.showMessage('Ошибка взаимодействия с сервером. Не удалось получить данные таблицы. Попробуйте повторить попытку позже.');
                } else {
                 console.log("Client Error:", err)

                    this.snackbarService.showMessage(err);
                }
            }
        },

        clearData() {
            this.column1 = [];
            this.column2 = [];
            console.log('IncompleteMnemonicsTable was cleared');
        },

        async showGenerationExplanations(code) {

            this.showOverlay = true;
            this.code = code;
            this.pageNumber = 1;
                
            try {
                console.log('Started downloading code generation explanation...');
                
                const url = this.urlBuilder.buildUrlForCodeGenerationExplanation(code);
                const response = await axios.get(url);
                if (response.status != 200) {
                    throw "Не удается подключиться к серверу";
                }
                
                if (!response.data.isSuccess)
                {
                    console.log(response.data.errors);
                    throw "Упс... Что-то пошлоа не так. Попробуйте позже.";
                }

                this.generationExplanations = response.data.body;

                console.log('Completed downloading code generation explanation');
            } 
            catch (err) {

                if (err.response) {
                    // client received an error response (5xx, 4xx)
                    console.log("Server Error:", err)
                    this.snackbarService.showMessage('Ошибка взаимодействия с сервером. Не удалось получить объяснение, как генерируется код. Попробуйте повторить попытку позже.');
                } else if (err.request) {
                    // client never received a response, or request never left
                    console.log("Network Error:", err)

                    this.snackbarService.showMessage('Ошибка взаимодействия с сервером. Не удалось получить объяснение, как генерируется код. Попробуйте повторить попытку позже.');
                } else {
                 console.log("Client Error:", err)

                    this.snackbarService.showMessage(err);
                }

                this.hideGenerationExplanations();
            }
        },

        hideGenerationExplanations() {
            this.showOverlay = false;
            this.generationExplanations = [];
            this.code = null;
            this.pageNumber = 1;
        },

        async sortAsync() {
            let sortModes = new SortModes();
            console.log("Sorting... " + this.sortMode.name)
            switch (this.sortMode.name) {
                case sortModes.Default:
                    this.mnemonicManager.column1.forEach(group => {
                        group.entries.sort(this.sortByIndexAsc);
                    });
                    this.mnemonicManager.column2.forEach(group => {
                        group.entries.sort(this.sortByIndexAsc);
                    });
                    break;
                case sortModes.CodePerGroupAsc:
                    this.mnemonicManager.column1.forEach(group => {
                        group.entries.sort(this.sortByCodesAsc);
                    });
                    this.mnemonicManager.column2.forEach(group => {
                        group.entries.sort(this.sortByCodesAsc);
                    });
                    break;
                case sortModes.CodePerGroupDesc:
                    this.mnemonicManager.column1.forEach(group => {
                        group.entries.sort(this.sortByCodesDesc);
                    });
                    this.mnemonicManager.column2.forEach(group => {
                        group.entries.sort(this.sortByCodesDesc);
                    });
                    break;
                case sortModes.NamePerGroupAlphabeticallyAsc:
                    this.mnemonicManager.column1.forEach(group => {
                        group.entries.sort(this.sortByNameAlphabeticallyAsc);
                    });
                    this.mnemonicManager.column2.forEach(group => {
                        group.entries.sort(this.sortByNameAlphabeticallyAsc);
                    });
                    break;
                case sortModes.NamePerGroupAlphabeticallyDesc:
                    this.mnemonicManager.column1.forEach(group => {
                        group.entries.sort(this.sortByNameAlphabeticallyDesc);
                    });
                    this.mnemonicManager.column2.forEach(group => {
                        group.entries.sort(this.sortByNameAlphabeticallyDesc);
                    });
                    break;
            }
        },

        sortByIndexAsc(a, b) {
            if (a.index < b.index) return -1;
            if (a.index === b.index) return 0;

            return 1;
        },

        sortByCodesAsc(a, b) {
            if (a.code < b.code) return -1;
            if (a.code === b.code) return 0;

            return 1;
        },

        sortByCodesDesc(a, b) {
            if (a.code < b.code) return 1;
            if (a.code === b.code) return 0;

            return -1;
        },
        
        sortByNameAlphabeticallyAsc(a, b) {
            if (a.name < b.name) return -1;
            if (a.name === b.name) return 0;

            return 1;
        },

        sortByNameAlphabeticallyDesc(a, b) {
            if (a.name < b.name) return 1;
            if (a.name === b.name) return 0;

            return -1;
        }
    },
    
  mounted() {
      this.fetchTableAsync();

      this.$on('logout', this.clearData);
  },
}
</script>


import axios from "axios"
import RequestsPage from './../core/RequestsPage.js'
import MnemonicCheckRequest from './../core/MnemonicCheckRequest.js'

export default class MnemonicCheckRequestsService {
    constructor(urlBuilder, snackbarService) {
        this.requests = [];
        this.timer = null;
        this.requestsPage = new RequestsPage(0, 4, 0);
        this.isFirstAutoRefreshFetch = true;
        this.urlBuilder = urlBuilder;
        this.snackbarService = snackbarService;
    }

    startAutoRefreshRequests() {
        console.log('Started running timer for request auto refreshing...');
        if (!this.timer) {
            this.timer = setInterval(() => {
      
              if (this.requests === null) return;

              // check do we need refresh data
              var needRefreshRequests = false;

                // if not, let's check saved requests, if any requests is active
                for (let i = 0; i < this.requests.length; ++i) {
                    let request = this.requests[i];
                
                    if (request.isActive) {
                        needRefreshRequests = true;
                        break;
                    } 
                }
              
              // if need - load data
              if (needRefreshRequests) {
                this.loadRequests();
              }
            }, 3000);
            console.log('Completed running timer for request auto refreshing...');

            // load first data
            this.loadRequests();
        }
        else {
            console.log('Auto refreshing has been already started');
        }
    }

    async loadRequests() {
        try
        {
          // fetch data from api
          const url = this.urlBuilder.buildUrlForGetRequests(this.requestsPage.pageNumber, this.requestsPage.pageSize);
          const response = await axios.get(url);
  
          if (response.status != 200) {
              throw "Не удается подключиться к серверу";
          }
          
          if (!response.data.isSuccess)
          {
            
              console.log(response.data.errors);

              if (response.data.errors.length > 0) {
                throw response.data.errors[0].description;
              }
              throw "Упс... Не удалось загрузить список запросов. Попробуйте позже.";
          }
  
          const data = response.data.body;
          console.log('Fetched %s requests', data.length);
  
          // save pagination info
          this.requestsPage = new RequestsPage(response.data.pageIndex, response.data.pageSize, response.data.totalCount); 
  
          // clear old data
          this.requests = [];
  
          // display it
          data.forEach(r => {
            let request = new MnemonicCheckRequest(
                r.id,
                r.name,
                r.state,
                r.indexes,
                r.completedMnemonicsCount,
                r.validMnemonicsCount,
                r.validMnemonicsWithActivityCount,
                r.allWalletsChecked,
                r.duplicatesCount,
                r.tableType
              );
            this.addRequest(request, false);
          });
        }
        catch (error) {
          console.log(error);
          this.snackbarService.showMessage(error.message);
        }
    }

    addRequest(request, addToBeginning) {
      if (addToBeginning === true) {
          this.requests.unshift(request);
        }
        else {
          this.requests.push(request);
        }
    }

    clearData() {
      if (this.timer) {
        clearInterval(this.timer)
      }

      this.requests = [];
    }

    async recheckRequest(requestId) {
      try
      {
        // fetch data from api
        const url = this.urlBuilder.buildUrlForRequestRecheck(requestId);
        const response = await axios.post(url);

        if (response.status != 200) {
            throw "Не удается подключиться к серверу";
        }
        
        if (!response.data.isSuccess)
        {
          
            console.log(response.data.errors);

            if (response.data.errors.length > 0) {
              throw response.data.errors[0].description;
            }
            throw "Упс... Не удалось отправить запрос на пересчет. Попробуйте позже.";
        }


        this.snackbarService.showMessage('Запрос с Id=' + requestId + ' отправлен на пересчет');

        await this.loadRequests();
      }
      catch (error) {
        console.log(error);
        this.snackbarService.showMessage(error.message);
      }
  }
}
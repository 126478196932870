export default class SortModes {
    constructor( ) {
        this.Default = 'Default';
        this.CodePerGroupAsc = 'CodePerGroupAsc';
        this.CodePerGroupDesc = 'CodePerGroupDesc'; 
        this.NamePerGroupAlphabeticallyAsc = 'NamePerGroupAlphabeticallyAsc';
        this.NamePerGroupAlphabeticallyDesc = 'NamePerGroupAlphabeticallyDesc'; 
    }

    getCaption(sortMode) {
        switch (sortMode) {
            case this.Default:
                return 'По умолчанию';
            case this.CodePerGroupAsc:
                return 'В группе: По коду ↓';
            case this.CodePerGroupDesc:
                return 'В группе: По коду ↑';
            case this.NamePerGroupAlphabeticallyAsc:
                return 'В группе: По названию (алфавит) ↓';
            case this.NamePerGroupAlphabeticallyDesc:
                return 'В группе: По названию (алфавит) ↑';
            default:
                throw 'Неподдерживаемый режим сортировки:' + sortMode;
        }
    }
}


export default class SnackbarService {
    constructor() {
        this.showSnackbar = false;
        this.snackbarText = '';
    }

    showMessage(message) {
        this.snackbarText = message;
        this.showSnackbar = true;
    }

    hideMessage() {
        this.showSnackbar = false;
    }
}
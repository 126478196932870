export default class MnemonicWord {
    constructor(index, name, code, isOptional, isDuplicatedInColumn, isDuplicatedInTable) {
        this.index = index;
        this.name = name;
        this.code = code;
        this.isOptional = isOptional;
        this.isDuplicatedInColumn = isDuplicatedInColumn;
        this.isDuplicatedInTable = isDuplicatedInTable;
        this.counters = [];
    }
}
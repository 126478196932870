export default class MnemonicCheckRequest {
    constructor (id, name, state, indexes, completedMnemonicsCount, validMnemonicsCount, validMnemonicsWithActivityCount, allWalletsChecked, duplicatesCount, tableType) {
        this.id = id;
        
        if (name === null) {
            this.name = 'Новый запрос';
        }
        else {
            this.name = name;
        }
        this.state = state;
        this.indexes = indexes;
        this.completedMnemonicsCount = completedMnemonicsCount ?? 0;
        this.validMnemonicsCount = validMnemonicsCount ?? 0;
        this.validMnemonicsWithActivityCount = validMnemonicsWithActivityCount ?? 0;
        this.allWalletsChecked = allWalletsChecked;
        this.duplicatesCount = duplicatesCount;

        this.showDuplicates = this.duplicatesCount > 1;

        this.isQueued = this.state == 1;
        this.isProcessing = this.state == 2;
        this.isFailed = this.state == 4;
        this.isWaitingForWallets = this.state == 3 && !this.allWalletsChecked;
        this.hasNoValidWithActivity = this.allWalletsChecked && this.validMnemonicsWithActivityCount === 0; 
        this.hasValidWithActivity = this.allWalletsChecked && this.validMnemonicsWithActivityCount > 0;     

        if (this.isQueued) {
            this.statusCaption = 'В очереди'.toUpperCase();
            this.isActive = true;
        }
        if (this.isProcessing) {
            this.statusCaption = 'Поиск кошельков'.toUpperCase();
            this.isActive = true;
        }
        if (this.isFailed) {
            this.statusCaption = 'Ошибка'.toUpperCase();
            this.isActive = false;
        }
        if (this.isWaitingForWallets) {
            this.statusCaption = 'Проверка баланса'.toUpperCase();
            this.isActive = true;
        }
        if (this.hasNoValidWithActivity) {
            this.statusCaption = 'Нет активных'.toUpperCase();
            this.isActive = false;
        }
        if (this.hasValidWithActivity) {
            this.statusCaption = 'Есть активные'.toUpperCase();
            this.isActive = false;
        }

        // we can recheck only already processed requests
        this.canRecheck = !this.isQueued && !this.isProcessing; 

        this.tableType = tableType;

        if (tableType === 1) {
            this.tableTypeTooltip = 'Основная таблица';
        }
        else if (tableType === 2) {
            this.tableTypeTooltip = 'Дополнительная таблица';
        }
    }
} 
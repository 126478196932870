export default class UrlBuilder {
    constructor(host) {
        this.host = host;

    }

    buildUrlForLogin() {
        return this.host + 'auth';
    }

    buildUrlForTableData() {
        return this.host + 'incomplete-mnemonics/table';
    }

    buildUrlForCodeGenerationExplanation(code) {
        return this.host + 'incomplete-mnemonics/table/word/code/' + code + '/generation/bip/explanation';
    }


    buildUrlForAddRequest() {
        return this.host + 'mnemonic-check-requests';
    }


    buildUrlForGetRequests(pageNumber, pageSize) {
        return this.host + 'mnemonic-check-requests?pi=' + (pageNumber -1) + '&ps=' + pageSize;
    }

    buildUrlForGetRequest(requestId) {
        return this.host + 'mnemonic-check-requests/' + requestId;
    }

    buildUrlForGetRequestToNotify() {
        return this.host + 'mnemonic-check-requests/to-notify';
    }

    buildUrlForRequestRecheck(requestId) {
        return this.host + 'mnemonic-check-requests/' + requestId + '/recheck';
    }
}
<template>

    <div class="d-flex flex-column flex-wrap">
        <v-card class="mb-3">
            <v-card-title class="blue white--text">
                <span class="text-h5">Запросы на проверку</span>
            </v-card-title>
            <v-card-text class="mt-3">
                <v-container fluid>
                    <v-data-iterator
                        :items="requests"
                        item-key="id"
                        :items-per-page="requestsPage.pageSize"
                        hide-default-footer>

                        <template v-slot:default="{ items }">
                            <v-row 
                                v-for="item in items"
                                :key="item.id">
                                <v-card class="mb-3 bwkr__request__card">
                                    <v-card-title class="bwkr__request__title" v-bind:class="{ bwkr__request__queued: item.isQueued, bwkr__request__processing: item.isProcessing, bwkr__request__failed: item.isFailed, bwkr__request__waiting__for__wallets: item.isWaitingForWallets, bwkr__request__has__valid__with__activity: item.hasValidWithActivity, bwkr__request__has__no__valid__with__activity: item.hasNoValidWithActivity}">
                                        <v-container>
                                            <div class="d-flex align-center">
                                                <v-tooltip left>
                                                    <template v-slot:activator="{on, attrs}">
                                                        <v-btn
                                                            fab
                                                            x-small
                                                            dark
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            class="mr-2 pr-0 ml-0"
                                                            @click="restorePhrase(item.indexes)">
                                                            <v-icon>mdi-eye</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Показать комбинацию в таблице</span>
                                                </v-tooltip>

                                                <v-tooltip right>
                                                    <template v-slot:activator="{on, attrs}">
                                                        <v-btn
                                                            fab
                                                            x-small
                                                            dark
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            class="mr-2 pr-0 ml-0"
                                                            v-show="item.canRecheck"
                                                            @click="recheckRequest(item.id)">
                                                            <v-icon>mdi-refresh</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Проверить комбинацию еще раз</span>
                                                </v-tooltip>

                                                <span class="bwkr__request__card__id">{{ item.id }}</span>
                                                

                                                <div class="ml-auto">
                                                    <v-chip class="bwkr__request__card__status" >
                                                        <div class="d-flex align-center">
                                                            <h4 class="mr-3">{{ item.statusCaption }}</h4>
                                                            <v-progress-circular
                                                                :width="3"
                                                                color="red pt-1 pb-1 pl-1 pr-1"
                                                                indeterminate
                                                                class="mt-3 mb-3"
                                                                v-show="item.isActive"
                                                                >
                                                            </v-progress-circular>
                                                            <h2 v-show="item.isFailed">
                                                                😡
                                                            </h2>
                                                            <h2 v-show="item.hasNoValidWithActivity">
                                                                👎
                                                            </h2>
                                                            <h2 v-show="item.hasValidWithActivity">
                                                                🎉
                                                            </h2>
                                                        </div>
                                                    </v-chip>
                                                </div>
                                            </div>
                                        </v-container>
                                    </v-card-title>
                                    <v-card-text>
                                        <div class="d-flex flex-wrap">
                                            <v-chip class="mr-2 mt-3">Проверено комбинаций: {{ item.completedMnemonicsCount }}</v-chip>

                                            <v-chip class="mr-2 mt-3">Корректных ключей: {{ item.validMnemonicsCount }} </v-chip>

                                            <v-chip class="mr-2 mt-3">Активных кошельков: {{ item.validMnemonicsWithActivityCount }}</v-chip>
                                            

                                            <v-tooltip right>
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-chip
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        class="mr-2 mt-3"
                                                        v-show="item.tableType !== null">
                                                        
                                                        <h4>T{{item.tableType}}</h4>

                                                    </v-chip>
                                                </template>
                                                <span>{{item.tableTypeTooltip}}</span>
                                            </v-tooltip>


                                            <v-tooltip left>
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-chip 
                                                        v-show="item.showDuplicates"
                                                        v-on="on"
                                                        v-bind="attrs"
                                                        class="mr-2 mt-3 bwkr__request__card__status" >
                                                        <div class="d-flex align-center">
                                                            <h4 class="mr-3">
                                                                <v-icon left>
                                                                    mdi-content-duplicate
                                                                </v-icon>
                                                            </h4>
                                                            <h4>
                                                                {{ item.duplicatesCount }}
                                                            </h4>
                                                        </div>
                                                    </v-chip>
                                                </template>
                                                <span>Количество запросов на проверку по такой же комбинации (дубликатов)</span>
                                            </v-tooltip>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-row>
                        </template>
                    </v-data-iterator>
                </v-container>
            </v-card-text>
        </v-card>
        <v-pagination
            v-model="requestsPage.pageNumber"
            :length="requestsPage.pagesCount"
            :total-visible="6"
            @input="loadRequests"
            >
        </v-pagination>
    </div>
</template>

<style scoped>

.bwkr__request__card {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
}

.bwkr__request__title {
    padding-top: 2px;
    padding-bottom: 2px;
}

.bwkr__request__card__id {
    min-width: 50px;
}

.bwkr__request__card__name {
    text-align: left;
    margin-left: 5px;
}

.bwkr__request__queued {
    background-color: rgb(243,243,243) !important;
}

.bwkr__request__processing {
    background-color: rgb(207,226,243) !important;
}

.bwkr__request__failed {
    background-color: rgb(204,65,37) !important;
}

.bwkr__request__waiting__for__wallets {
    background-color: rgb(255,229,153) !important;
}

.bwkr__request__has__valid__with__activity {
    background-color:rgb(182,215,168) !important;
}

.bwkr__request__has__no__valid__with__activity {
    background-color:rgba(244, 204, 204) !important;
}

</style>

<script>

import MnemonicManager from './../core/MnemonicManager.js'
import RequestsPage from './../core/RequestsPage.js'
import MnemonicCheckRequestsService from './../core/MnemonicCheckRequestsService.js'

export default {
    name: "CheckRequests",

    props: {
        mnemonicManager: MnemonicManager,
        mnemonicCheckRequestsService: MnemonicCheckRequestsService
    },


    computed: {
        requests: function() {
            if (this.mnemonicCheckRequestsService === null) return [];

            return this.mnemonicCheckRequestsService.requests;
        },

        requestsPage: function() {
            if (this.mnemonicCheckRequestsService === null) return new RequestsPage();

            return this.mnemonicCheckRequestsService.requestsPage;
        }
    },

    methods: {

        restorePhrase(indexes) {
            this.mnemonicManager.restorePhrase(indexes);
        },

        loadRequests(){
            this.mnemonicCheckRequestsService.loadRequests();
        },

        recheckRequest(requestId) {
            this.mnemonicCheckRequestsService.recheckRequest(requestId);
        }
    },

}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"bwkr__table mb-5 mb-md-0 mr-0 pr-0 pr-md-3"},[_c('v-row',[_c('v-col',{staticClass:"bwkr__table__column pr-sm-0 pb-sm-0"},[_c('IncompleteMnemonicTableColumn',{attrs:{"categories":_vm.column1,"mnemonicManager":_vm.mnemonicManager},on:{"showExplanation":_vm.showGenerationExplanations}})],1),_c('v-col',{staticClass:"bwkr__table__column pl-sm-0 pb-sm-0"},[_c('IncompleteMnemonicTableColumn',{attrs:{"categories":_vm.column2,"mnemonicManager":_vm.mnemonicManager,"showRightBorder":true},on:{"showExplanation":_vm.showGenerationExplanations}})],1),_c('v-overlay',{attrs:{"value":_vm.showOverlay}},[_c('v-card',[_c('v-card-title',[_c('v-container',[_c('div',{staticClass:"d-flex flex-wrap"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mr-3"},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.code)+" ")])]}}])},[_vm._v(" Так выглядит исходный код, для которого смотрим примеры генерации ")]),_c('h3',[_vm._v(" - текущий код ")]),_c('div',{staticClass:"ml-auto"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":_vm.hideGenerationExplanations}},'v-btn',attrs,false),on),[_vm._v(" X ")])]}}])},[_vm._v(" Закрыть окно с пояснениями ")])],1)],1)])],1),_c('v-card-text',[_c('v-data-iterator',{attrs:{"items":_vm.generationExplanations,"item-key":"ruleName","loading":"generationExplanations === null","loading-text":"Загрузка пояснений...","hide-default-footer":"","page":_vm.pageNumber,"items-per-page":_vm.pageSize},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return _vm._l((items),function(explanation){return _c('v-row',{key:explanation.ruleName},[_c('v-card',{staticClass:"my-2 mx-2",staticStyle:{"width":"100%"},attrs:{"light":""}},[_c('v-card-title',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mr-3"},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(explanation.generatedCode)+" ")])]}}],null,true)},[_vm._v(" Это код, который будет сгенерирован из исходного кода при применении этого правила ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" - "+_vm._s(explanation.ruleName)+" ")])]}}],null,true)},[_vm._v(" Это название правила ")])],1),_c('v-card-text',[_vm._v(" "+_vm._s(explanation.ruleDescription)+" ")])],1)],1)})}}])}),_c('v-pagination',{staticClass:"mt-3",attrs:{"length":_vm.pageCount,"total-visible":6},model:{value:(_vm.pageNumber),callback:function ($$v) {_vm.pageNumber=$$v},expression:"pageNumber"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-simple-table dense>
        <template v-slot:default>
            <colgroup>
                <col style="width: 3rem;"/>
                <col style="width: *;"/>
                <col style="width: auto;"/>
                <col style="width: auto;"/>
            </colgroup>
            <tbody>
                <template v-for="category in categories">
                    <tr :key="category.name" class="bwkr__red">
                        <td colspan="4" 
                            class="bwkr__table__group__header bwkr__table__row__text" 
                            :class="{bwkr__table__row__text__right: showRightBorder || $vuetify.breakpoint.mobile, bwkr__table__row__text__mobile: $vuetify.breakpoint.mobile, bwkr__table__row__text__laptop: !$vuetify.breakpoint.mobile, }" 
                        >
                            {{ category.name }}
                        </td>
                    </tr>
                    <tr
                        v-bind:class="{ bwkr__yellow: item.isOptional }"
                        v-for="item in category.entries"
                        :key="item.name"
                        @click="pushWord(item)"
                        @contextmenu.prevent="showExplanation(item)">
                        <td 
                            class="bwkr__table__row__text bwkr__table_chip__cell"
                            :class="{bwkr__table__row__text__mobile: $vuetify.breakpoint.mobile, bwkr__table__row__text__laptop: !$vuetify.breakpoint.mobile, }"
                        >
                            <template 
                                v-for="counter in item.counters">
                                <v-chip 
                                    class="bwkr__table__chip" 
                                    v-show="counter > 0"
                                    :key="counter"
                                    :class="{bwkr__table__chip__mobile: $vuetify.breakpoint.mobile, bwkr__table__chip__laptop: !$vuetify.breakpoint.mobile, }"
                                >
                                    {{ counter }}
                                </v-chip>
                            </template>
                        </td>
                        <td 
                            class="bwkr__table__row__text pl-1"
                            :class="{bwkr__table__row__text__mobile: $vuetify.breakpoint.mobile, bwkr__table__row__text__laptop: !$vuetify.breakpoint.mobile, }"
                        >
                            {{ item.name }}
                        </td>
                        <td 
                            class="bwkr__table__row__text"
                            :class="{bwkr__table__row__text__mobile: $vuetify.breakpoint.mobile, bwkr__table__row__text__laptop: !$vuetify.breakpoint.mobile, }"
                        >
                            {{ item.code }}
                        </td>
                        <td 
                            class="bwkr__table__row__text"  
                            :class="{bwkr__table__row__text__right: showRightBorder || $vuetify.breakpoint.mobile, bwkr__table__row__text__mobile: $vuetify.breakpoint.mobile, bwkr__table__row__text__laptop: !$vuetify.breakpoint.mobile, }"
                        >
                            {{ item.isOptional ? '+' : '-' }}
                        </td>
                    </tr>
                </template>
            </tbody>
        </template>
    </v-simple-table>
</template>

<style>

:root {
    --red-background-color: rgba(244, 204, 204);
    --red-foreground-color: black;
    
    --yellow-background-color: rgb(255, 229, 153);
    --yellow-foreground-color: black;
    
    --chip-background-color: rgb(159, 197, 232);
    --chip-foreground-color: black;
}

[data-theme="darkMode"] {
    --red-background-color: rgb(213, 57, 57);
    --red-foreground-color: white;
    
    --yellow-background-color: rgb(212, 163, 16);
    --yellow-foreground-color: black;
    
    --chip-background-color: rgb(13, 66, 115);
    --chip-foreground-color: white;
}

.bwkr__red {
    background: var(--red-background-color) !important;
    color: var(--red-foreground-color);
}

.bwkr__yellow {
    background: var(--yellow-background-color);
    color: var(--yellow-foreground-color);
}

.bwkr__table__row__text {
    border-left: 1px solid;
    border-top: 1px solid;
    border-bottom: 1px solid;
}

.bwkr__table__row__text__mobile {
    font-size: 1.1rem !important;
}

.bwkr__table__row__text__laptop {
    font-size: 0.65rem !important;
}

.bwkr__table__row__text__right {
    border-right: 1px solid;
}


.bwkr__table__group__header {
    text-align: center;;
}

.bwkr__table__chip {
    min-width: 32px !important;
    text-align: center;
    background: var(--chip-background-color) !important;
    color: var(--chip-foreground-color);
}

.bwkr__table__chip__mobile {
    height: 0.95rem !important;
    font-size: 0.9rem !important;
}

.bwkr__table__chip__laptop {
    height: 0.65rem !important;
    font-size: 0.7rem !important;
}

.bwkr__table_chip__cell {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
    min-width: 36px !important;
    text-align: center;
}

tr, td {
    height: 1rem !important;
}

thead {
    height: 1rem !important;
    font-size: 0.5rem !important;
}

</style>

<script>

import MnemonicManager from './../core/MnemonicManager.js';

export default {
    name: 'IncompleteMnemonicTableColumn',
   
    props: {
        categories: Array,
        mnemonicManager: MnemonicManager,
        showRightBorder: Boolean
    },

    methods: {
        pushWord(word){
            this.mnemonicManager.pushWord(word)
        },

        showExplanation(word) {
            this.$emit('showExplanation', word.code)
            console.log('Hello from child!');
        }
    }
}
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-wrap"},[_c('v-card',{staticClass:"mb-3"},[_c('v-card-title',{staticClass:"blue white--text"},[_c('span',{staticClass:"text-h5"},[_vm._v("Запросы на проверку")])]),_c('v-card-text',{staticClass:"mt-3"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-data-iterator',{attrs:{"items":_vm.requests,"item-key":"id","items-per-page":_vm.requestsPage.pageSize,"hide-default-footer":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return _vm._l((items),function(item){return _c('v-row',{key:item.id},[_c('v-card',{staticClass:"mb-3 bwkr__request__card"},[_c('v-card-title',{staticClass:"bwkr__request__title",class:{ bwkr__request__queued: item.isQueued, bwkr__request__processing: item.isProcessing, bwkr__request__failed: item.isFailed, bwkr__request__waiting__for__wallets: item.isWaitingForWallets, bwkr__request__has__valid__with__activity: item.hasValidWithActivity, bwkr__request__has__no__valid__with__activity: item.hasNoValidWithActivity}},[_c('v-container',[_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2 pr-0 ml-0",attrs:{"fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.restorePhrase(item.indexes)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Показать комбинацию в таблице")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.canRecheck),expression:"item.canRecheck"}],staticClass:"mr-2 pr-0 ml-0",attrs:{"fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.recheckRequest(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}],null,true)},[_c('span',[_vm._v("Проверить комбинацию еще раз")])]),_c('span',{staticClass:"bwkr__request__card__id"},[_vm._v(_vm._s(item.id))]),_c('div',{staticClass:"ml-auto"},[_c('v-chip',{staticClass:"bwkr__request__card__status"},[_c('div',{staticClass:"d-flex align-center"},[_c('h4',{staticClass:"mr-3"},[_vm._v(_vm._s(item.statusCaption))]),_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(item.isActive),expression:"item.isActive"}],staticClass:"mt-3 mb-3",attrs:{"width":3,"color":"red pt-1 pb-1 pl-1 pr-1","indeterminate":""}}),_c('h2',{directives:[{name:"show",rawName:"v-show",value:(item.isFailed),expression:"item.isFailed"}]},[_vm._v(" 😡 ")]),_c('h2',{directives:[{name:"show",rawName:"v-show",value:(item.hasNoValidWithActivity),expression:"item.hasNoValidWithActivity"}]},[_vm._v(" 👎 ")]),_c('h2',{directives:[{name:"show",rawName:"v-show",value:(item.hasValidWithActivity),expression:"item.hasValidWithActivity"}]},[_vm._v(" 🎉 ")])],1)])],1)],1)])],1),_c('v-card-text',[_c('div',{staticClass:"d-flex flex-wrap"},[_c('v-chip',{staticClass:"mr-2 mt-3"},[_vm._v("Проверено комбинаций: "+_vm._s(item.completedMnemonicsCount))]),_c('v-chip',{staticClass:"mr-2 mt-3"},[_vm._v("Корректных ключей: "+_vm._s(item.validMnemonicsCount)+" ")]),_c('v-chip',{staticClass:"mr-2 mt-3"},[_vm._v("Активных кошельков: "+_vm._s(item.validMnemonicsWithActivityCount))]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.tableType !== null),expression:"item.tableType !== null"}],staticClass:"mr-2 mt-3"},'v-chip',attrs,false),on),[_c('h4',[_vm._v("T"+_vm._s(item.tableType))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tableTypeTooltip))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.showDuplicates),expression:"item.showDuplicates"}],staticClass:"mr-2 mt-3 bwkr__request__card__status"},'v-chip',attrs,false),on),[_c('div',{staticClass:"d-flex align-center"},[_c('h4',{staticClass:"mr-3"},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-content-duplicate ")])],1),_c('h4',[_vm._v(" "+_vm._s(item.duplicatesCount)+" ")])])])]}}],null,true)},[_c('span',[_vm._v("Количество запросов на проверку по такой же комбинации (дубликатов)")])])],1)])],1)],1)})}}])})],1)],1)],1),_c('v-pagination',{attrs:{"length":_vm.requestsPage.pagesCount,"total-visible":6},on:{"input":_vm.loadRequests},model:{value:(_vm.requestsPage.pageNumber),callback:function ($$v) {_vm.$set(_vm.requestsPage, "pageNumber", $$v)},expression:"requestsPage.pageNumber"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }